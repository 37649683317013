import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FireBaseContext } from "../../Context/FireBase";
import { useParams } from "react-router-dom";
import { collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useEffect, useContext, useState } from "react";
import swal from "sweetalert";

export default function SearchEvent({ newSelected, setSelected }) {
  const { EventRefrence, SubscribersRefrence, getData } = useContext(FireBaseContext);
  const [data, setData] = useState([]);
  const [Result, setResult] = useState([]);
  const [value, setValue] = useState(null);
  const { dbID } = useParams();

  useEffect(() => {
    getData(EventRefrence, setData);
  }, []);

  useEffect(() => {
    if (data.length !== 0) {
      const filteredData = data.filter(({ ID }) => ID !== dbID);
      setResult(filteredData);
    }
  }, [data]);

  const ChangeEvent = async () => {
    if (!newSelected || newSelected.length === 0 || !value) {
      swal({
        title: "No event or subscribers selected!",
        text: "Please select both an event and at least one subscriber.",
        icon: "warning",
      });
      return;
    }

    const eventRef = doc(EventRefrence, dbID);
    const collectionEvent = collection(eventRef, "Subscribers");

    swal({
      title: `Are you sure?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          icon: "success",
          title: `this subscribers transferd from event ${dbID} to event ${value.ID}`
        });
        for (const item of newSelected) {
          try {
            const deletedItemRef = doc(collectionEvent, item);
            const subscriberDoc = await getDoc(deletedItemRef);
            if (!subscriberDoc.exists()) {
              console.warn(`Subscriber with ID ${item} does not exist in the old event.`);
              continue;
            }

            const subscriber = subscriberDoc.data();

            // Add subscriber to the new event
            const eventWillAddedTo = doc(EventRefrence, value.ID);
            const CollectionWillAddTo = collection(eventWillAddedTo, "Subscribers");
            await setDoc(doc(CollectionWillAddTo, item), subscriber);

            // Delete the subscriber from the old event
            await deleteDoc(deletedItemRef);

            // Update the eventID in the subscriber reference
            const subscriberRef = doc(SubscribersRefrence, item);
            await updateDoc(subscriberRef, {
              eventID: value.ID,
            });
          } catch (error) {
            console.error(`Error processing subscriber ${item}:`, error);
          }
        }

        setSelected([]);
      }
    });
  };


  return (
    <>
      <label className="block text-black text-sm font-bold mt-4 mb-2">Events</label>
      <Autocomplete
        className="appearance-none  pr-0 "
        id="grouped-demo"
        value={value}
        options={Result}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        getOptionLabel={(option) => `Event: ${option.Id}`}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
          // label="Events"
          // style={{ borderBottom: "1px solid black" }}
          />
        )}
      />
      <div className="d-flex flex-col gap-2 mt-2 mr-3 justify-content-center align-items-center">
        <span>
          <b> from event</b> : <span className="text-dark">{dbID}</span>
        </span>
        <span>
          <b> to event</b> : <span className="text-dark">{value ? value.ID : ''}</span>
        </span>
        <button
          disabled={value == null}
          onClick={ChangeEvent}
          className={`rounded disabled:opacity-75  border-0 btn-DarkBlue px-4 py-2 text-white mb-3 `}
        >
          Save
        </button>
      </div>
    </>
  );
}
