import React, { memo, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FireBaseContext } from "../../Context/FireBase";

const TimePicker = ({ condition, SetError, formErrors }) => {
  const { setNewEvent, newEvent } = useContext(FireBaseContext);
  const handleTimeChange = (time) => {
    if (time != null) {
      const timing = time.toLocaleTimeString();
      if (condition) {
        setNewEvent({ ...newEvent, DateFromHours: timing });
        SetError({ ...formErrors, DateFromHours: "" });
      } else {
        setNewEvent({ ...newEvent, DateEndHours: timing });
        SetError({ ...formErrors, DateEndHours: "" });
      }
    } else {
      if (condition) {
        SetError({ ...formErrors, DateFromHours: "Required" });
      } else {
        SetError({ ...formErrors, DateEndHours: "Required" });
      }
    }
  };

  return (
    <div className="timePikcerParent ">
      <div className=" rounded rounded-2 relative ">
        <DatePicker
      
          onChange={handleTimeChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={10}
          dateFormat="h:mm aa"
          timeCaption="Time"
          value={condition ? newEvent.DateFromHours : newEvent.DateEndHours}
          className=" border bg-transparent  w-100"
          placeholderText=""
        
        />
        <div className="absolute top-3 right-3">
        <i className="fa-regular fa-clock"></i>
        </div>

        {/* <input
          onChange={handleTimeChange}
          value={condition ? newEvent.DateFromHours : newEvent.DateEndHours}
          id="from-time"
          type="time"
          className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
        /> */}
      </div>
    </div>
  );
};

export default memo(TimePicker);


// greyBgc h-100 dropDownBorder p-2 overflow-x-hidden d-flex justify-content-start align-items-center