import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { FireBaseContext } from "../../Context/FireBase";

import swal from "sweetalert";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  height:200
};

export default function AddCityModal() {
  const { Cities } = useContext(FireBaseContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [checkCity,setCheckCity]=useState(true)
  const [cityName, setCityName] = useState("");
  const docRef = doc(Cities, "City");

  const handleAddCity = async () => {
    const docSnap = await getDoc(docRef);
   
    const x = docSnap.data().data.find((y)=>y==cityName)
    if(!x){
  if (docSnap.exists()) {
      const newData = docSnap.data().data || []; // Get existing array or initialize empty array
      newData.push(cityName); // Push new item into the array
      await updateDoc(docRef, { data: newData });

      swal({
        icon: "success",
        title: `${cityName} Added`,
      }); // Update document with the new array
      handleClose();
    } else {
    }
    setCheckCity(true)
    }else{
      setCheckCity(false)
    }
  
  };

  return (
    <div className="BtnOthers">
      <Button onClick={handleOpen}>
        <small>Others</small>{" "}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="">
          <Typography id="modal-modal-description">
            <div className=" ">
              <h6>City Name:</h6>
              <div className="w-100 mt-3">
                <input
                  className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  onChange={(e) => setCityName(e.target.value)}
                  

                />
              </div>
              <button
                className="w-100 bg-blueColor text-white mt-3 p-2 rounded"
                onClick={handleAddCity}
              >
                <i className="fa-solid fa-plus mr-2"></i>
                Add
              </button>
            </div>
            <p className={`text-danger ${checkCity ? "d-none" : "d-block"}`}>
              This City exist
            </p>
            <div></div>{" "}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
