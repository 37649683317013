import { initializeApp } from "@firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyArzfBBs8KRz65kngP3oCVviQKmpkx7aQ0",
  authDomain: "events-test-d0c63.firebaseapp.com",
  databaseURL: "https://events-test-d0c63-default-rtdb.firebaseio.com",
  projectId: "events-test-d0c63",
  storageBucket: "events-test-d0c63.appspot.com",
  messagingSenderId: "363477602090",
  appId: "1:363477602090:web:000cb6e93c73ae14dd5853",
  measurementId: "G-N6TXN5BWPN"
};
const AdminApp = initializeApp(firebaseConfig, "Admin");
// const AdminDatabase = getFirestore(AdminApp);
export const AdminAuth = getAuth(AdminApp);



