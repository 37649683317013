import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import leftLogo from "../../assets/Abbott.png";
import rightlogo from "../../assets/Orevan.png";
import center from "../../assets/Asset 1@4x.png";
import eventLogo from "../../assets/LoginPage.png";
import { Formik } from "formik";
import { FireBaseContext } from "../../Context/FireBase";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { getData, UserRef } = useContext(FireBaseContext);
  const [informations, setInformations] = useState([]);
  const navigate = useNavigate()
  const handleReturnButton = () => {
     navigate('/')
  }

  useEffect(() => {
    getData(UserRef, setInformations);
  }, []);

  const auth = getAuth();
  function forgotPassword(email) {
    return sendPasswordResetEmail(auth, email, {
      url: "https://event.orevan.org",
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const userExists = informations.some((user) => user.Email === email);
    console.log("User exists:", userExists);

    if (userExists) {
      forgotPassword(email)
        .then((response) => {
          console.log(response);
          toast.success("Request done, check your mail", { autoClose: 4000 });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message, { autoClose: 4000 });
        });
    } else {
      toast.error("Email does not exist in the system.", { autoClose: 4000 });
      return;
    }
  };

  return (
    <div className="flex w-full h-screen">
      <ToastContainer />
      <div className="w-full flex items-center justify-center lg:w-1/2">
        <div className="bg-white px-10 py-20 rounded-3xl border-2 border-gray-100 shadow xl:w-3/5 lg:w-3/4 md:w-3/5">
          <div className="flex justify-center">
            <img
              src={center}
              alt=""
              className="w-50"
              style={{ objectFit: "contain" }}
            />
          </div>
          <h2 className="font-medium text-2xl text-gray-500 mt-4  text-center">
            Forgot Password
          </h2>
          <div className="mt-8">
            <Formik>
              {() => (
                <form>
                  <div>
                    <label className="text-lg font-medium my-3" htmlFor="">
                      Email
                    </label>
                    <input
                      name="email"
                      id="email"
                      className="w-full border border-gray-100  p-3  bg-transparent"
                      placeholder="Enter Your Email.."
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-center align-items-center"></div>
                  </div>
                  <div className="mt-8 flex flex-col gap-y-4">
                    <button
                      onClick={onSubmit}
                      type="submit"
                      className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl bg-gradient-to-tr from-purpleColor to-darkColor text-white text-lg font-bold w-100 flex justify-center items-center gap-3"
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </form>
              )}
            </Formik>
            <div className="mt-4">
              <button onClick={handleReturnButton} className="text-blueColor cursor-pointer">
              
                Return to Login Page
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden relative lg:flex h-full w-1/2 items-center justify-center flex-col bg-gray-100 ">
        <div className="mt-[-3vw] mr-[15vw]">
          <div className="w-[18vw] h-[18vw] bg-gradient-to-tr from-blueColor to-blue-500 rounded-full animate-spin"></div>
        </div>
        <div className="mt-[-12vw] z-10">
          <img className="w-[20vw]" src={eventLogo} alt="" />
        </div>
        <div className="mt-[-10vw] ml-[8vw]">
          <div className="w-[18vw] h-[18vw] bg-gradient-to-tr from-purpleColor to-darkColor rounded-full animate-spin"></div>
        </div>

        <div className="w-full  absolute bottom-0 bg-white/10 backdrop-blur-lg flex justify-end flex-col ">
          <div className="grid  lg:grid-cols-4  xl:grid-cols-6 gap-x-32 lg:px-4 xl:px-10 mb-4 ">
            <div className="lg:col-span-2 xl:col-span-3 mt-3">
              <img className="w-4/6" src={leftLogo} alt="" />
            </div>
            <div className="lg:col-span-2 xl:col-span-3  flex justify-end">
              <img
                src={rightlogo}
                className="w-50"
                alt=""
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;





// const onSubmit = (e) => {
//     e.preventDefault();
//     forgotPassword(email)

//     .then(response => {
//         console.log(response);

//         toast.success("Request done , Check your mail", {
//             autoClose: 4000,
//           });

//     }).catch(err =>  {

//         console.log(err);
//         toast.error(err, {
//             autoClose: 4000,
//           });
//     })
// }
