import { createContext } from "react";
import data from "../Json/currencies.json";

export const HelperContext = createContext();

const HelperContextProvider = ({ children }) => {

  function FormateCost(number, eventCurrency) {
    const currencyObj = data.data.find(
      (item) => item.name === eventCurrency
    );

    const cost = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 20, // Adjust based on the precision you want
    }).format(number);

    return cost + " " + currencyObj.symbol;
  }

  return (
    <HelperContext.Provider value={{ FormateCost }}>
      {children}
    </HelperContext.Provider>
  );
};
export default HelperContextProvider;
