import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {  useCallback  } from "react";

const ExportEventsExcel = ({ data, filename, sheetname,  onExportStart, onExportEnd }) => {
  const extractData = useCallback(
  
    data.map((item) => ({
      "Event Name": item.eventName,
      Franchise: item.Franchise.toString(),
      "Event ID": item.Id,
      City: item.city.map((city) => city).join(","),
      "Cost per Delegate": item.CostperDelegate,
      "Event Cost": item.eventCost?.toString(),
      PO: item.PO,
      BeSure: item.BeSure,
      "Event Date": item.eventDate,
      "Created By": item.CreatedBy.Name,
    })),
    [data]
  );


  const exportToExcel = useCallback(async () => {
    onExportStart()
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetname);
    const headersList = [
      "Event ID",
      "Event Name",
      "Franchise",
      "City",
      "Cost per Delegate",
      "Event Cost",
      "PO",
      "BeSure",
      "Event Date",
      "Created By",
    ];
    worksheet.addRow([...headersList]);

    const rowIndex = 1;
    const row = worksheet.getRow(rowIndex);
    row.eachCell({ includeEmpty: true }, (cell, index) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "80FFFF00" }, // ARGB format for light red color
      };
      cell.font = { size: 12 };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    extractData.map((rowItem, index) => {
      const holder = headersList.map((head) => {
        const convertItem = isNaN(Number(rowItem[head]))
          ? rowItem[head]
          : Number(rowItem[head]);
        return rowItem[head] ? convertItem : "";
      });
      worksheet.addRow([...holder]);

      const rowIndex = index + 2;
      const row = worksheet.getRow(rowIndex);
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.fill = rowItem.hasOwnProperty("Event Name") && {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFADD8E6" }, // ARGB format for light blue color
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });
    });

    worksheet.columns.forEach((column, colIndex) => {
      let maxLength = 0;

      worksheet.eachRow({ includeEmpty: true }, (row, rowIndex) => {
        const cellValue = row.getCell(colIndex + 1).text;
        maxLength = Math.max(maxLength, cellValue ? cellValue.length : 0);
      });

      column.width = maxLength + 5;
    });

    worksheet.getRow(1).height = 20;

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, filename);
    onExportEnd();
    
  }, [extractData, filename, sheetname, onExportStart, onExportEnd ]); // Add necessary dependencies

  return (
    <i className="fa-solid fa-download" onClick={exportToExcel}>
      <span className="fs-6 fw-light"> Orevan</span>
    </i>
  );
};

export default ExportEventsExcel;