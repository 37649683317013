import { useContext, useEffect, useState } from "react";
import { FireBaseContext } from "../../Context/FireBase";
import data from "../../Json/currencies.json";
export const PreviewCard = () => {
  const { newEvent } = useContext(FireBaseContext);
  const [currency, setCurrency] = useState({});

  useEffect(() => {
    const currencyObj = data.data.find((item) => item.name == newEvent.EventCurrency);
    setCurrency(currencyObj);
  }, []);

  return (
    <div className="w-full px-2">
      <h4 className="wrappingItems text-white p-2 w-fit">ID : {newEvent.Id}</h4>

      <div className="flex gap-x-6 ">
        <div className="w-full md:w-1/2  mb-2">
          <div className="">
            <p className="my-2 text-gray-600 font-thin">
              <b>Event Name:</b>
            </p>
            <p className="my-2 text-black font-semibold text-2xl">
              {newEvent.eventName}
            </p>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2">
              <div className="">
                <p className="my-2 text-gray-600 font-thin">
                  <b>BeSure:</b>
                </p>
                <p className="my-2 text-black text-xl">{newEvent.BeSure}</p>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="">
                <p className="my-2 text-gray-600 font-thin">
                  <b>Franchise:</b>
                </p>
                <p className="my-2 text-black text-xl ">{newEvent.Franchise}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2">
              <div className="">
                <p className="my-2 text-gray-600 font-thin">
                  <b>Start Date:</b>
                </p>
                <p className="my-2 text-black text-xl"> {newEvent.eventDate}</p>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="">
                <p className="my-2 text-gray-600 font-thin">
                  <b>End Date:</b>
                </p>
                <p className="my-2 text-black text-xl"> {newEvent.endDate}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2">
              <div className="">
                <p className="my-2 text-gray-600 font-thin">
                  <b>Start Time:</b>
                </p>
                <p className="my-2 text-black text-xl">
                  {" "}
                  {newEvent.DateFromHours}
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="">
                <p className="my-2 text-gray-600 font-thin">
                  <b>End Time:</b>
                </p>
                <p className="my-2 text-black text-xl">
                  {" "}
                  {newEvent.DateEndHours}
                </p>
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="">
              <p className="my-2  text-gray-600 font-thin">
                <b>City:</b>
              </p>
              <div className="flex flex-wrap gap-2">
                {newEvent.city.map((item, index) => (
                  <div
                    className="bg-purpleColor rounded text-white p-2 w-fit text-sm"
                    key={index}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full  md:w-1/2">
          <div className="">
            <p className="my-2 text-gray-600 font-thin">
              <b>Cost per Delegate:</b>
            </p>
            <p className="my-2 text-black text-xl">
              {newEvent.CostperDelegate} {currency.symbol}
            </p>
          </div>
          <div className="">
            <p className="my-2 text-gray-600 font-thin">
              <b>Transfer of value:</b>
            </p>

            <div className="flex flex-wrap gap-2">
              {newEvent.TransferOfValue.map((item, index) => (
                <div
                  className="bg-gray-400 rounded flex items-center  w-fit  my-2"
                  key={index}
                >
                  {/* <p className="my-2 "> */}
                  <span className="text-white text-sm p-2">{item.types} </span>
                  <span className="text-white bg-purpleColor rounded-tr rounded-br flex items-center justify-center h-100 p-2 text-sm">
                    {item.value}
                  </span>
                  {/* </p> */}
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col flex-wrap gap-2">
            <div className="">
              <p className="my-2 text-gray-600 font-thin">
                <b>Font Color:</b>
              </p>
              <div
                className="border"
                style={{
                  backgroundColor: `${newEvent.fontColor}`,
                  width: "50px",
                  height: "20px",
                  borderRadius: "5px",
                }}
              ></div>
            </div>

            <div className="">
              <p className="my-2 text-gray-600 font-thin">
                <b>Background Color:</b>
              </p>
              <div
                className="border"
                style={{
                  backgroundColor: `${newEvent.bgColor}`,
                  width: "50px",
                  height: "20px",
                  borderRadius: "5px",
                }}
              ></div>
            </div>

            <div className="">
              <p className="my-2 text-gray-600 font-thin">
                <b>Button Color:</b>
              </p>
              <div
                className="border"
                style={{
                  backgroundColor: `${newEvent.btnColor}`,
                  width: "50px",
                  height: "20px",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};