import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import ExportEventsExcel from "../ExportEventsExcel/ExportEventsExcel";
import ExportSfda from "../ExportSfda/ExportSfda";
export default function ExportDropDown({
  rows,
  startDateFilter,
  endDateFilter,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isExporting, setIsExporting] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleExportStart = () => {
    setIsExporting(true); // Show spinner
  };

  const handleExportEnd = () => {
    setIsExporting(false); // Hide spinner
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="flex justify-center items-center gap-2  "
      >
        {/* <i className="fa-solid fa-file-arrow-down fs-5 text-white"></i>
        <span className="text-white block leading-4">export</span> */}
        {isExporting ? (
          <i className="gg-spinner-two-alt fs-5 text-white animate-spin"></i>
        ) : (
          <i className="fa-solid fa-file-arrow-down fs-5 text-white"></i>
        )}
        <span className="text-white block leading-4">export</span>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>
          <ExportEventsExcel
            filename={
              startDateFilter && endDateFilter
                ? `${startDateFilter} - ${endDateFilter} Orevan Report`
                : " Orevan Report"
            }
            sheetname="Sheet 1"
            data={rows}
            onExportStart={handleExportStart} // Start spinner
            onExportEnd={handleExportEnd} // Stop spinner
            
          />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ExportSfda
            filename={
              startDateFilter && endDateFilter
                ? `${startDateFilter} - ${endDateFilter} SFDA Report`
                : "SFDA Report"
            }
            sheetname="Sheet 1"
            data={rows}
            onExportStart={handleExportStart} // Start spinner
            onExportEnd={handleExportEnd} // Stop spinner
          />
        </MenuItem>
      </Menu>
    </div>
  );
}
